<template>
    <div class="actual-cate-manage">
        <CateTemplate v-bind="templateConfig"></CateTemplate>
    </div>
</template>

<script>
import CateTemplate from "components/admin/classificationManage/CateTemplate";
import { operateActualCate, getActualCateData, deleteActualCate, operationProblemExamModuleList } from "@/utils/apis";

export default {
    name: "ActualCateManage",
    data() {
        return {
            templateConfig: {
                operateCate: operateActualCate,
                getData: getActualCateData,
                deleteCate: deleteActualCate,
                actualOptions: {
                    options: [],
                    props: {
                        label: "name",
                        value: "id",
                    },
                },
            },
        };
    },
    components: {
        CateTemplate,
    },
    methods: {
        async init() {
            let res = await operationProblemExamModuleList();
            if (res.code == 200) {
                this.templateConfig.actualOptions.options = res.data; 
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.actual-cate-manage {
    padding: 0 20px;
    height: 100%;
}
</style>
